import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { qty: 1 };
  }
  onAddToCartClicked() {
    this.props.addItemToCart(this.props.product, this.state.qty)
  }

  onQuantityChanged(event) {
    this.setState({ qty: parseInt(event.target.value) });
  }

  render() {
    console.log(this.props.product.images[0])
    return (
      <div className="mt-5 mx-auto h-100">
        <h1 className="text-center">{this.props.product.title}</h1>
        <img src={this.props.product.images[0]} style={{ width: 400}}  alt="First slide" />
        <h5 className="mx-auto">Price : ${this.props.product.price} USD</h5>
          <Form>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>Select Qty</Form.Label>
              <Form.Control as="select" onChange={this.onQuantityChanged.bind(this)}>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Form.Control>
            </Form.Group>
          </Form>
          <Button onClick={this.onAddToCartClicked.bind(this)}>Add to Cart</Button>

      </div>
    );
  }
}

export default ProductDetails;
