import React from "react";
import Table from "react-bootstrap/Table";

export class CartSummary extends React.Component {
  render() {
    let cartTable = [];
    var totalQty = 0;
    var totalAmount = 0;
    var i = 1;
    for (var sku in this.props.cart) {
      const item = this.props.cart[sku];
      console.log(item.product);

      let td = [];
      td.push(<td key={i}>{i++}</td>);
      td.push(<td key={i + "title"}>{item.product.title}</td>);
      td.push(<td key={i + "qty"}>{item.qty}</td>);
      td.push(<td key={i + "price"}>{item.product.price} USD</td>);
      cartTable.push(<tr key={sku}>{td}</tr>);
      totalQty = totalQty + item.qty;
      totalAmount = totalAmount + item.qty * item.product.price;
    }
    return (
      <Table className="styled-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {cartTable}

          <tr>
            <th colSpan="2" className="text-center">
              Total
            </th>
            <th>{totalQty}</th>
            <th>{totalAmount} USD</th>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default CartSummary;
