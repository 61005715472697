import React from "react";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import "../styles/Checkout.css";
import Button from "react-bootstrap/Button";
import uuid from 'react-uuid'

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://checkout.ravi.ws";
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#87BBFD",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);


const DEFAULT_STATE = {
  error: null,
  cardComplete: false,
  processing: false,
  paymentMethod: null,
  email: "",
  address: "",
  name: "",
  orderId: uuid()
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  componentDidMount() {
    // Create items list from cart
    var cartItems = [];
    for (var sku in this.props.cart) {
      const item = this.props.cart[sku];
      cartItems.push({
        id: item.product.sku,
        qty: item.qty,
        price: item.product.price,
      });
    }
    // Create PaymentIntent as soon as the page loads
    fetch(baseURL + "/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-idempotency-key" : this.state.orderId
      },
      body: JSON.stringify({
        items: cartItems,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({ clientSecret: data.clientSecret });
      });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { stripe, elements } = this.props;
    const {
      email,
      name,
      error,
      clientSecret,
      cardComplete,
    } = this.state;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      this.setState({ processing: true });
    }

    const payload = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: email,
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: name,
          email: email,
        },
      },
    });

    this.setState({ processing: false });

    if (payload.error) {
      this.setState({ error: payload.error });
    } else {
      this.setState({ paymentIntent: payload.paymentIntent });
    }
  };

  reset = () => {
    this.setState(DEFAULT_STATE);
    window.location.reload();
  };

  render() {
    const {
      error,
      processing,
      paymentIntent,
      name,
      email,
      address,
    } = this.state;
    const { stripe } = this.props;
    return paymentIntent ? (
      <div className="Result">
        <div className="ResultTitle" role="alert">
          Payment successful
        </div>
        <div className="ResultMessage">
          Thanks for shopping with us. No money was charged, we have generated
          payment intent: <strong>{paymentIntent.id}</strong> with total amount
          : <strong> {paymentIntent.amount / 100} USD</strong>
        </div>
        <Button onClick={this.reset}>Start Again</Button>
      </div>
    ) : (
      <form className="Form" onSubmit={this.handleSubmit}>
        <fieldset className="FormGroup">
          <Field
            label="Name"
            id="name"
            type="text"
            placeholder="Enter your full name"
            required
            autoComplete="name"
            value={name}
            onChange={(event) => {
              this.setState({ name: event.target.value });
            }}
          />
          <Field
            label="Email"
            id="email"
            type="email"
            placeholder="Enter your email address"
            required
            autoComplete="email"
            value={email}
            onChange={(event) => {
              this.setState({ email: event.target.value });
            }}
          />
          <Field
            label="Address"
            id="address"
            type="text"
            placeholder="Enter your shipping address"
            value={address}
            onChange={(event) => {
              this.setState({ address: event.target.value });
            }}
          />
        </fieldset>
        <fieldset className="FormGroup">
          <CardField
            onChange={(event) => {
              this.setState({
                error: event.error,
                cardComplete: event.complete,
              });
            }}
          />
        </fieldset>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <SubmitButton processing={processing} error={error} disabled={!stripe}>
          Pay
        </SubmitButton>
      </form>
    );
  }
}

export class StripeCheckoutForm extends React.Component {
  render() {
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <CheckoutForm
            cart={this.props.cart}
            stripe={stripe}
            elements={elements}
            totalAmount={this.props.totalAmount}
          />
        )}
      </ElementsConsumer>
    );
  }
}

export default StripeCheckoutForm;
