import React from "react";
import Row from "react-bootstrap/Row"
import { ProductCard } from "./ProductCard";
export class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { products: [] };
  }
  componentDidMount() {
    fetch("/products.json")
      .then((response) => response.json())
      .then((data) => this.setState({ products: data }));
  }
  render() {
    const products = this.state.products.map((product, i) => (
      <ProductCard  onSelectedProductChanged={this.props.onSelectedProductChanged} key={i} product={product}>
        {product.title}
      </ProductCard>
    ));
    return (<div > 
      <Row><h4 className="mx-auto mt-5">Choose a Product</h4></Row>
      <Row><h1 className="mx-auto">👇</h1></Row>
      {products}
    </div>);
  }
}

export default ProductList;
