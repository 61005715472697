import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import AnimateOnChange from "react-animate-on-change";

export class Header extends React.Component {
  render() {
    var itemsInCart = Object.keys(this.props.cart).length;
    return (
      <>
        <Navbar bg="light">
          <Nav>
            <Navbar.Brand onClick={ () => {    window.location.reload();}}>
              <img
                src="/logo512.png"
                width="30"
                height="30"
                className="d-inline-block align-top mr-3"
                alt="React Bootstrap logo"
              />
              eCommerce Store
            </Navbar.Brand>
          </Nav>
          {itemsInCart === 0 ? (
            <div></div>
          ) : (
              <Nav className="ml-auto">
                <AnimateOnChange baseClassName="cart-enter" animationClassName="cart" animate={itemsInCart !== 0}>
                <Button
                  variant="success"
                  onClick={this.props.showCart}
                >
                  📦 &nbsp; <Badge variant="light">{itemsInCart}</Badge>
                  <span className="sr-only">unread messages</span>
                </Button>
                </AnimateOnChange>
              </Nav>
          )}
        </Navbar>
      </>
    );
  }
}
export default Header;
