import Container from "react-bootstrap/Container";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CartSummary from "../components/CartSummary";
import StripeCheckoutForm from "../components/StripeCheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_L4ZRUGdoM3VZOb06mIehC5sv");

export class Checkout extends React.Component {
  render() {
    return (
      <div>
        <Container className="mt-5 pt-5">
          <Row>
            <Col>
              <Row>
                <h4 className="mx-auto  mb-5">Cart Summary</h4>
              </Row>
              <Row>
                <CartSummary cart={this.props.cart}></CartSummary>
              </Row>
            </Col>
            <Col>
              <Row>
                <h4 className="mx-auto mb-5">Payment Information</h4>
              </Row>
              <Elements stripe={stripePromise}>
                <StripeCheckoutForm cart={this.props.cart} />
              </Elements>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Checkout;
