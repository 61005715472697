import React from "react";

import Header from "./components/Header";
import Home from "./pages/Home"
import CartModal from "./pages/CartModal";
import Checkout from "./pages/Checkout";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: undefined,
      showCart: false,
      showCheckout: false,
      cart: {},
    };
  }
  onShowCheckout() {
    this.setState({ showCheckout: true, showCart: false });
  }
  onSelectedProductChanged(product) {
    this.setState({ selectedProduct: product });
  }
  addItemToCart(product, qty) {
    var cartItems = this.state.cart;
    const sku = product.sku;
    if (sku in cartItems) {
      cartItems[sku].qty += qty;
    } else {
      cartItems[sku] = { qty: qty, product: product };
    }
    this.setState({ cart: cartItems });
    console.log(this.state.cart);
  }

  render() {
    return (
      <div>
        <Header
          cart={this.state.cart}
          showCart={() => {
            this.setState({ showCart: true });
          }}
        ></Header>
        {this.state.showCheckout === true ? (
          <Checkout cart={this.state.cart}></Checkout>
        ) : (
          <Home addItemToCart={this.addItemToCart.bind(this)}> </Home>
        )}
        <CartModal
          cart={this.state.cart}
          showCart={this.state.showCart}
          showCheckout={this.onShowCheckout.bind(this)}
          hideCart={() => {
            this.setState({ showCart: false });
          }}
        ></CartModal>
      </div>
    );
  }
}

export default App;
