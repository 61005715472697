import React from "react";
import Card from "react-bootstrap/Card";

export class ProductCard extends React.Component {
  onProductClicked() {
    this.props.onSelectedProductChanged(this.props.product)
    // console.log(this)
  }

  render() {
    return (
      <Card onClick={this.onProductClicked.bind(this)} className="m-3">
        <Card.Img style={{ width: "8rem" }} src={this.props.product.thumbnail} className="mx-auto"/>
        <Card.Body>
          <Card.Title className="font-weight-bold text-center">{this.props.product.title}</Card.Title>
          <Card.Text className="mx-auto">
                {this.props.product.description}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}
export default ProductCard