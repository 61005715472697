import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CartSummary from "../components/CartSummary";

export class CartModal extends React.Component {
  render() {
    return (
      <Modal show={this.props.showCart} onHide={this.props.hideCart}>
        <Modal.Header closeButton>
          <Modal.Title>📦 What's in your box?</Modal.Title>
        </Modal.Header>
        <Container>
          <Modal.Body>
            <CartSummary cart={this.props.cart}></CartSummary>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.hideCart}>
              Close
            </Button>
            <Button variant="primary" onClick={this.props.showCheckout}>
              Checkout
            </Button>
          </Modal.Footer>
        </Container>
      </Modal>
    );
  }
}

export default CartModal;
