import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import ProductList from "../components/ProductList";
import ProductDetails from "../components/ProductDetails";

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: undefined,
    };
  }
  onSelectedProductChanged(product) {
    this.setState({ selectedProduct: product });
  }
  addItemToCart(product, qty) {
    var cartItems = this.state.cart;
    const sku = product.sku;
    if (sku in cartItems) {
      cartItems[sku].qty += qty;
    } else {
      cartItems[sku] = { qty: qty, product: product };
    }
    this.setState({ cart: cartItems });
    console.log(this.state.cart);
  }

  render() {
    return (
      <div>
        <Row>
          <Col sm={3} className="products">
            <ProductList
              onSelectedProductChanged={this.onSelectedProductChanged.bind(
                this
              )}
            ></ProductList>
          </Col>
          {this.state.selectedProduct === undefined ? (
            <Container className="mt-5 ">
              <Jumbotron  className="mt-5">
                <h1>Welcome to the T-Shirt store.</h1>
                <p>
                  Start by selecting your favuorite t-shirt
                </p>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </Jumbotron>
            </Container>
          ) : (
            <ProductDetails
              addItemToCart={this.props.addItemToCart}
              product={this.state.selectedProduct}
            ></ProductDetails>
          )}
        </Row>
      </div>
    );
  }
}

export default Home;
